import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    h4: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    h5: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    h6: {
      fontFamily: '"Source Serif Pro", Georgia, Times, serif;',
      color: "#262b3a",
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'Montserrat, Avenir, "Avenir Next", Trebuchet, Verdana, sans-serif;',
      color: "#262b3a",
      fontWeight: 400,
    },
  },
});

export default theme;
